import React, { useState } from "react";
import PropTypes from "prop-types";
import { TbLoaderQuarter } from "react-icons/tb";

const ButtonStatus = Object.freeze({
    IDLE: "IDLE",
    SENDING: "SENDING",
    SENT: "SENT",
    ERROR: "ERROR",
});

function SubmitButton({
    buttonText = "Enviar",
    successText = "Enviado",
    defaultErrorText = "Erro",
    submitTimeout = 10000,
    sentSuccessDuration = 5000,
    onSubmit,
}) {
    const [status, setStatus] = useState(ButtonStatus.IDLE);
    const [errMsg, setErrorMsg] = useState(defaultErrorText);

    const handleSent = async (e) => {
        setStatus(ButtonStatus.SENDING);

        const timeoutPromise = new Promise((_, reject) =>
            setTimeout(() => reject(new Error("Request timeout")), submitTimeout)
        )

        try {
            await Promise.race([onSubmit(e), timeoutPromise]);
            setStatus(ButtonStatus.SENT);
        } catch (error) {
            //TODO: log the errors to a centralized logging system
            console.error("Error:", error);

            setErrorMsg(error.message ?? defaultErrorText)
            setStatus(ButtonStatus.ERROR);
        } finally {
            setTimeout(() => setStatus(ButtonStatus.IDLE), sentSuccessDuration);
        }
    }

    return <div className="flex items-center justify-center min-h-8">
        {status === ButtonStatus.SENT && (
            <div className="flex items-center space-x-2 text-green-600">
                <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 13l4 4L19 7"
                    />
                </svg>
                <span className="font-medium text-lg whitespace-pre-line">{successText}</span>
            </div>
        )}

        {status === ButtonStatus.ERROR && (
            <div className="flex items-center space-x-2 text-red-600">
                <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                    />
                </svg>
                <span className="font-medium text-lg">{errMsg}</span>
            </div>
        )}

        {status === ButtonStatus.IDLE &&
            <button
                type="submit"
                onClick={handleSent}
                disabled={status !== ButtonStatus.IDLE}
                className="m-auto bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
                {buttonText}
            </button>
        }

        {status === ButtonStatus.SENDING && (
            <button
                disabled
                className="px-4 py-2 flex items-center justify-center bg-gray-400 text-white rounded-lg cursor-not-allowed"
            >
                <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                    {/* <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path> */}
                    <TbLoaderQuarter color="black" />
                </svg>
            </button>
        )}
    </div>
}

SubmitButton.propTypes = {
    buttonText: PropTypes.string,
    successText: PropTypes.string,
    defaultErrorText: PropTypes.string,
    timeout: PropTypes.number,
    onSubmit: PropTypes.func.isRequired,
};

export default SubmitButton;