import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import HomePage from "./pages/HomePage";
import ContactsPage from "./pages/Contacts";
import OrderPage from "./pages/Order";
// import Showroom from "./pages/Showroom";
import DetailsPage from "./pages/Details";
import DetailsPageOld from "./pages/DetailsOld";
import NotFoundPage from "./pages/NotFound";

import Header from './parts/Header'
import Footer from './parts/Footer'

function App() {
  return (
    <div className="App flex flex-col justify-center min-h-screen items-center">
      <Router>
        <Header theme={"white"} />
        <Routes >
          <Route exact path="/" element={<HomePage />} />
          {/* <Route exact path="/" element={<DetailsPage />} /> */}
          <Route exact path="/home" element={<HomePage />} />
          <Route path="/contacts" element={<ContactsPage />} />
          {/* <Route path="/details/:idc" element={<DetailsPage />} /> */}
          <Route path="/details" element={<DetailsPage />} />
          <Route path="/details_old" element={<DetailsPageOld />} />
          <Route path="/order" element={<OrderPage />} />
          {/* <Route path="/showroom" element={<Showroom />} /> */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
