import React, { useState } from 'react';
import SubmitButton from '../components/SubmitButton/SubmitButton';

export default function Order() {
    const budgetOptions = [
        {
            label: "<50",
            text: "< 50.000 €"
        },
        {
            label: "50-100",
            text: "50.000 € - 100.000 €"
        },
        {
            label: "100-200",
            text: "100.000 € - 200.000 €"
        },
        {
            label: "200+",
            text: "200.000 € +"
        }
    ]

    const carSpotted = [
        {
            value: true,
            text: "Sim"
        },
        {
            value: false,
            text: "Não"
        }
    ]

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        budget: '',
        model: '',
        color: '',
        year: '',
        kilometers: '',
        notes: '',
    });

    const [errors, setErrors] = useState({
        name: '',
        email: '',
        phone: '',
    });

    const [isVisible, setIsVisible] = useState(false);

    const toggleCarDetailsVisibility = (e) => {
        const { value } = e.target;
        setIsVisible(value === "true");
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (errors[name].length !== 0) {
            setErrors({
                ...errors,
                [name]: '',
            });

        }

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // function validate() {
    //     let validationErrors = {};
    //     let isValid = true;

    //     if (!formData.name) {
    //         validationErrors.name = 'Nome é obrigatório';
    //         isValid = false;
    //     }

    //     if (!formData.email) {
    //         validationErrors.email = 'Email é obrigatório';
    //         isValid = false;
    //     } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
    //         validationErrors.email = 'Email inválido';
    //         isValid = false;
    //     }

    //     if (!formData.phone) {
    //         validationErrors.phone = 'Telefone é obrigatório';
    //         isValid = false;
    //     }

    //     setErrors(validationErrors);
    //     return isValid;
    // }


    const handleSubmit = async (e) => {
        e.preventDefault();

        // if (!validate()) {
        //     throw {
        //         type: 'validation',
        //         details: Object.values(errors).filter((k, v) => v !== ""),
        //         message: 'Dados inválidos.',
        //     };
        // }

        // Do something with form data
        console.log('Form Data:', formData);

        //Mock API call
        await new Promise(resolve => setTimeout(resolve, 3000));
    };

    const inputClassName = `
    mt-1 
    block 
    w-full 
    px-3 
    py-2 
    border 
    border-gray-300 
    rounded-md 
    shadow-sm 
    focus:outline-none 
    focus:ring-indigo-500 
    focus:border-indigo-500
    cursor-pointer
    `

    //TODO: add data validation such as email and phone number

    return (
        <div className="flex grow flex-col py-6 md:py-16 px-4 w-full max-w-screen-xl">
            <div className=" my-10 md:my-10">
                <img
                    className='max-h-20 mx-auto'
                    src="/images/content2/logo.png"
                    alt="El-Store Logo"
                />
            </div>
            <div className='text-3xl my-3  md:my-20 font-bold w-full text-center'>Tornamos o seu sonho <br /> uma realidade</div>
            <form onSubmit={handleSubmit} className="space-y-4 flex flex-col flex-grow justify-around">
                <div className='grid grid-cols-1 gap-4 md:grid-cols-2 grid-flow-row !auto-rows-min'>
                    <div >
                        <label htmlFor='name'>
                            Nome
                        </label>
                        <input
                            type='text'
                            id='name'
                            name='name'
                            value={formData.name}
                            onChange={handleChange}
                            required
                            className={inputClassName}
                        />
                        {errors.name && <p className="text-red-600 text-sm">{errors.name}</p>}
                    </div>
                    <div>
                        <label htmlFor='email'>
                            Email
                        </label>
                        <input
                            type='email'
                            id='email'
                            name='email'
                            value={formData.email}
                            onChange={handleChange}
                            required
                            className={inputClassName}
                        />
                        {errors.email && <p className="text-red-600 text-sm">{errors.email}</p>}
                    </div>
                    <div>
                        <label htmlFor='phone'>
                            Telefone
                        </label>
                        <input
                            type='tel'
                            id='phone'
                            name='phone'
                            value={formData.phone}
                            onChange={handleChange}
                            required
                            className={inputClassName}
                        />
                        {errors.phone && <p className="text-red-600 text-sm">{errors.phone}</p>}
                    </div>
                    <div>
                        <label htmlFor="budget" className="text-sm font-medium text-gray-700">
                            Budget
                        </label>
                        <select
                            id="budget"
                            name="budget"
                            value={formData.budget}
                            onChange={handleChange}
                            required
                            className={inputClassName}
                        >
                            {budgetOptions.map((elem, id) => {
                                return <option key={id} value={elem.label}>{elem.text}</option>
                            })}
                        </select>
                    </div>
                    <div className=' col-span-2'>
                        <label htmlFor="spotted_car" className="text-sm font-medium text-gray-700">
                            Carro em vista
                        </label>
                        <select
                            id="car_spotted"
                            name="car_spotted"
                            value={isVisible}
                            onChange={toggleCarDetailsVisibility}
                            className={inputClassName}
                        >
                            {carSpotted.map((elem, id) => {
                                return <option key={id} value={elem.value}>{elem.text}</option>
                            })}
                        </select>
                    </div>


                    {/* <h1 className="text-2xl font-bold mb-6 text-center col-span-2">Carro em Vista</h1> */}
                    {/* <div className="flex items-center mb-6 col-span-2">
                        <span className="mr-3 text-gray-700">Carro em Vista</span>
                        <div
                            onClick={toggleCatDetailsVisibilitySwitch}
                            className={`w-12 h-6 flex items-center bg-gray-300 rounded-full p-1 cursor-pointer ${isVisible ? 'bg-indigo-600 bg-green-500' : ''
                                }`}
                        >
                            <div
                                className={`bg-white w-5 h-5 rounded-full shadow-md transform ${isVisible ? 'translate-x-6' : ''
                                    }`}
                            ></div>
                        </div>
                    </div> */}

                    {isVisible && <div className="grid grid-cols-1 col-span-2 gap-4 md:grid-cols-2 !auto-rows-min">
                        <div >
                            <label htmlFor='model'>
                                Modelo
                            </label>
                            <input
                                type='text'
                                id='model'
                                name='model'
                                value={formData.model}
                                onChange={handleChange}

                                className={inputClassName}
                            />
                        </div>
                        <div>
                            <label htmlFor='color'>
                                Cor
                            </label>
                            <input
                                type='text'
                                id='color'
                                name='color'
                                value={formData.color}
                                onChange={handleChange}
                                className={inputClassName}
                            />
                        </div>
                        <div>
                            <label htmlFor='year'>
                                Ano
                            </label>
                            <input
                                type='number'
                                id='year'
                                name='year'
                                value={formData.year}
                                onChange={handleChange}
                                className={inputClassName}
                            />
                        </div>
                        <div>
                            <label htmlFor='kms'>
                                Max quilómetros
                            </label>
                            <input
                                type='number'
                                id='kilometers'
                                name='kilometers'
                                value={formData.kilometers}
                                onChange={handleChange}
                                className={inputClassName}
                            />
                        </div>
                    </div>}
                    <div className='col-span-2'>
                        <label htmlFor="notes" >
                            Notas
                        </label>
                        <textarea
                            id="notes"
                            name="notes"
                            value={formData.notes}
                            onChange={handleChange}
                            rows="4"
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                        ></textarea>
                    </div>
                </div>
                <SubmitButton
                    successText='Entraremos em contacto assim que possível'
                    errorText='Tente um contacto alternativo'
                    onSubmit={handleSubmit}
                />
            </form>
        </div >
    )
}