import React from 'react'
// import Header from '../parts/Header'
// import JustArrived from '../parts/HomePage/JustArrived'
// import BrowseRoom from '../parts/HomePage/BrowseRoom'
// import Sitemap from '../parts/Sitemap'
// import Footer from '../parts/Footer'
// import BreadCrumb from '../components/BreadCrumb/BreadCrumb'
// import ProductDetails from '../parts/Details/ProductDetails'
import Suggestion from '../parts/Details/Suggestion'


var car = {
    mainImage: {
        url: "images/content2/porsche-1.webp",
        alt: "gray porsche panamera"
    },
    header: {
        title: "Porsche Panameta Xpto",
        subtitle: "La máquina turbida",
        description: "Feita em liga de aluminio de frigorificos reciclados, um dislumbre do futuro que nos arrefece os pés com a força bruta de 467 cv de potência.\n Perca o Sangue nas extremidades com o coice do animal."
    },
    properties: [
        {
            section: "Geral",
            details: [
                {
                    name: "Marca",
                    value: "Porsche"
                },
                {
                    name: "Modelo",
                    value: "Panarema"
                },
                {
                    name: "Cor exterior",
                    value: "Cinzento"
                },
                {
                    name: "Interior",
                    value: "Pele sintetica de cor roxa"
                }
            ]
        },
        {
            section: "Opções",
            details: [
                {
                    name: "Assistencia\n estacionamento",
                    value: "Cameras de marcha atrás"
                },
                {
                    name: "Retrovisores",
                    value: "Aquecidos"
                },
                {
                    name: "Teto panoramico",
                    value: "Vidro laminado escurecido"
                }
            ]
        },
        {
            section: "Especificações tecnicas",
            details: [
                {
                    name: "Travões",
                    value: "Cerâmicos perfurados 20mm"
                },
                {
                    name: "Escape",
                    value: "Linha direta"
                },
                {
                    name: "Alerón",
                    value: "Matias, corta o vento às fatias"
                }
            ]
        }
    ]

}



export default function Details() {
    var selected = 0

    console.log(selected)
    return (
        <div className="flex grow flex-col py-6 md:py-16 w-full max-w-screen-xl">
            {/* <div class="grid grid-cols-1 md:grid-cols-2 gap-8 bg-white shadow-lg rounded-lg overflow-hidden"> */}
            <img src={car.mainImage.url} alt={car.mainImage.alt} class="w-full h-full object-cover" />
            <div class="flex flex-col p-6">
                <div class="mb-6">
                    <h1 class="text-2xl font-bold">{car.header.title}</h1>
                    <p class="text-gray-500">{car.header.subtitle}</p>
                    <p class="text-sm text-gray-600">{car.header.description}</p>
                </div>
                <Suggestion />
                <div className='flex flex py-4 max-h-80'>
                    <div class="flex flex-col">
                        {car.properties.map((p, i) => {
                            return <div>
                                <button className={`text-black font-semibold px-4 py-2 ${selected === i ? 'text-black' : 'text-gray-800/50'} w-full`}>{p.section}</button>
                            </div>
                        })}
                        {/* <button class="text-black font-semibold px-4 py-2 border-b-2 border-black">OVERVIEW</button>
                        <button class="text-gray-400 px-4 py-2">OPTIONS</button>
                        <button class="text-gray-400 px-4 py-2">TECH SPECS</button> */}
                    </div>
                    <div class="flex grow justify-center">
                        <div className='flex flex-col justify-center'>
                            {car.properties.map((p) => {
                                return <div>
                                    {p.details.map((d) => {
                                        return <div className='flex w-full justify-center'>
                                            <div className='flex justify-end text-right border-r-2 border-black px-3 font-bold whitespace-pre-line w-1/2 py-1' >{d.name}</div>
                                            <div className='flex justify-start text-left px-3 whitespace-pre-line w-1/2 py-1' >{d.value}</div>
                                        </div>
                                    })}
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
